import React, { useState, useEffect } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Filters from './filters';
import Products from './products';

import useProducts from '../../../hooks/useProducts';
import { AddProductModal } from '../../common/modals/modals';
import { addProductCart } from '../../../middleware/cartFunctions';
import MiniBanner from './minibanner';
import Loader from '../../common/loader';
import useFilters, {
  getFilterData,
  getPriceRangesFromPriceString,
} from '../../../hooks/useFilters';
import EmptyJumbotron from '../../common/emptyJumbotron';

import useProductCount from '../../../hooks/useProductCount';
import seoData from './seo_data.json';

import { isMobile } from 'react-device-detect';
import { isDesign } from '../../../middleware/common-functions';

function ProductList() {
  const is_design = isDesign();
  const { primary, secondary, tertiary } = useParams();
  const [modal, setModal] = useState(false);
  const [addedProduct, setAddedProduct] = useState(false);
  const [pagination, setPagination] = useState(1);
  const [activeFilters, setActiveFilters] = useState([]);
  const [filterData, setFilterData] = useState(getFilterData());
  const [seo_info, setSeoInfo] = useState('');
  const query = useQuery();
  const limit = 20;
  const history = useHistory();
  const [pageCount, setPageCount] = useState(0);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = Number(searchParams.get('page'));

  useEffect(() => {
    Number.isInteger(currentPage) && currentPage > 0
      ? setPagination(currentPage)
      : setPagination(1);
  }, [currentPage]);

  useEffect(() => {
    setFilterData(getFilterData());
    setActiveFiltersFromFilterData();
    if (typeof window.vCustomer != 'undefined') {
      window.vCustomer.startEvent(window.vCustomer.EVENT_TAGS.PRODUCTS_LIST);
    }
    const page_name = `${primary}${
      secondary ? secondary.split('-').join(' ') : ''
    }`;
    const set_seo_info = seoData[page_name];
    if (set_seo_info) {
      setSeoInfo(set_seo_info);
    }

    return function cleanup() {
      if (typeof window.vCustomer != 'undefined') {
        window.vCustomer.finishEvent(window.vCustomer.EVENT_TAGS.PRODUCTS_LIST);
      }
    };
  }, [primary, secondary, tertiary]);

  const brands = filterData.brands.selected;
  const type_url = [tertiary, secondary, primary];
  const querySelector = type_url
    .map((i) => {
      if (i) {
        return i.split('-').join(' ');
      }
      return i;
    })
    .find((i) => {
      return i;
    }); ///send only last type;
  const grpsbi = [
    ...new Set(
      [
        ...filterData.type.selected,
        ...filterData.family.selected,
        querySelector,
      ]
        .map((word) => (word ? replaceWords(word) : word))
        .filter(Boolean)
    ),
  ];

  const urlTypes = [
    primary ? replaceWords(primary).split('-').join(' ') : '',
    secondary ? secondary.split('-').join(' ') : filterData.family.selected[0],
    tertiary ? tertiary.split('-').join(' ') : filterData.type.selected[0],
  ];

  const { result: products } = useProducts({
    brands,
    grpsbi,
    limit: limit,
    pagination,
    urlTypes,
    size: filterData.size.selected[0],
    additionalAttributes: filterData.others.selected[0],
    priceRanges: getPriceRangesFromPriceString(filterData.prices.selected),
    altoValorFirst: is_design,
  });

  const countFilters = {
    brands,
    grpsbi,
    urlTypes,
    size: filterData.size.selected[0],
    additionalAttributes: filterData.others.selected[0],
    //priceRanges: getPriceRangesFromPriceString(filterData.prices.selected),
  };

  const [count] = useProductCount(countFilters);

  useEffect(() => {
    const pageCountData = Math.ceil(count / limit);
    setPageCount(pageCountData - 1);
  }, [count, pagination]);

  const replacedUrlTypes = urlTypes.map((word) =>
    word ? replaceWords(word) : word
  );
  const { result: filters } = useFilters(replacedUrlTypes);
  ///everytime new products arrive, update filters
  useEffect(() => {
    if (filters) {
      const newFilters = filters;
      ['family', 'type', 'brands', 'size', 'others'].forEach((key) => {
        newFilters[key].selected = filterData[key].selected.filter(
          (elem) => elem && newFilters[key].elements.includes(elem)
        );
      });
      setFilterData(newFilters);
    }
  }, [filters]);

  const setActiveFiltersFromFilterData = () => {
    const active_arr = [];
    Object.keys(filterData).map((key) => {
      const a_fliter = query.get(key);
      if (a_fliter) {
        active_arr.push({ key, value: a_fliter });
        triggerFilterUpdate(a_fliter, key, 'checkbox', false);
      }
    });
    setActiveFilters([...active_arr]);
  };

  const updateFilters = (key) => {
    return (value, type) => {
      return triggerFilterUpdate(value, key, type);
    };
  };

  const updatePagination = (btn) => {
    window.scroll({
      top: 10,
      behavior: 'smooth',
    });
    const direction = btn.target.dataset.direction;
    let newPagination;
    if (direction === 'front' || direction === 'back') {
      newPagination = direction === 'front' ? pagination + 1 : pagination - 1;
    } else {
      newPagination = direction;
    }
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('page', newPagination);
    const newSearchString = queryParams.toString();
    history.push({
      pathname: location.pathname,
      search: newSearchString,
    });
    btn.target.blur();
  };

  const triggerFilterUpdate = (value, key, type, update_url = true) => {
    let activeFilters_current = [...activeFilters];
    const filterData_current = { ...filterData };
    const selectedElements = filterData_current[key].selected;
    const isSelected = selectedElements.includes(value);
    if (type === 'price' /* Mutually exclussive */) {
      if (isSelected) {
        filterData_current[key].selected = [];
      } else {
        filterData_current[key].selected = [value];
      }
    } /* type === "checkbox" */ else {
      if (isSelected) {
        const filtered = selectedElements.filter((v) => v !== value);
        filterData_current[key].selected = filtered;
        activeFilters_current = activeFilters_current.filter(
          (f) => f.key !== key
        );
      } else {
        selectedElements.push(value);
        activeFilters_current.push({ key, value });
      }
    }
    let filter_params_url = '';
    if (activeFilters_current.length) {
      filter_params_url = activeFilters_current
        .map((i) => `${i.key}=${i.value}`)
        .join('&');
    }
    if (update_url) {
      history.push({
        search: filter_params_url,
      });
    }
    setActiveFilters([...activeFilters_current]);
    setFilterData(filterData_current);
  };

  const promptAddProduct = async (ev) => {
    const product_id = ev.currentTarget.dataset.product_id;
    const product = products.find((p) => p.id === product_id);

    const product_amount = document.getElementById(
      `cant_input_list_${product_id}`
    ).value;
    product.amount = product_amount;
    setAddedProduct(product);
    await addProductCart({ product_amount, product_id, product_data: product });
    setModal(true);
  };
  const hideModal = () => {
    setModal(false);
  };

  const [mobFilter, setMobFilter] = useState(false);

  const toggleMobFilters = () => {
    setMobFilter(!mobFilter);
  };

  return (
    <div className="container-fluid first-container">
      <Helmet>
        <title>
          {seo_info
            ? seo_info.seo
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ') + '| Mathasa'
            : null}
        </title>
      </Helmet>
      <BreadcrumbSection filters={filterData} />
      <center>{!isMobile && <MiniBanner category={primary} />}</center>
      <div className="d-md-flex">
        <div className="d-md-none mt-2">
          <button
            onClick={toggleMobFilters}
            className="btn btn-block material-shadows py-3 mb-3"
          >
            <i className="fas fa-filter mr-2"></i> Filtros{' '}
          </button>
          {mobFilter && (
            <Filters filters={filterData} updateFilters={updateFilters} />
          )}
        </div>

        <div className="d-none d-md-inline">
          <h5 className="underlined my-4">Filtros</h5>
          <Filters filters={filterData} updateFilters={updateFilters} />
        </div>

        <div className="products_list">
          {products && products.length ? (
            <Products
              category_name={seo_info.title || tertiary || secondary}
              productsList={products}
              addProductCart={promptAddProduct}
            />
          ) : products || products == false ? (
            <EmptyJumbotron
              heading="No se encontraron productos"
              text="Intenta con otra categoría o diferentes filtros"
            />
          ) : (
            <Loader />
          )}
          {(products && products.length >= limit) || pagination > 1 ? (
            <PaginationBtn
              showNext={products && products.length >= limit}
              pagination={pagination}
              updatePagination={updatePagination}
              pageCount={pageCount}
            />
          ) : null}
        </div>
      </div>
      <AddProductModal
        active={modal}
        toggle={hideModal}
        product_title={addedProduct && addedProduct.descp1}
        type={
          addedProduct && addedProduct.xium
            ? addedProduct && addedProduct.xium.descrp
            : 'pz'
        }
        neddedProducts_ids={addedProduct && addedProduct.ptrl}
        relatedTypes={querySelector}
        product_amount={addedProduct.amount}
      />
    </div>
  );
}

const BreadcrumbSection = ({ filters }) => {
  const { primary, secondary, tertiary } = useParams();
  const selectedFilters = filters
    ? Object.keys(filters)
        .map((key) => {
          const item = filters[key];
          return [...item.selected];
        })
        .filter((i) => i.length > 0)
    : [];
  return (
    <Breadcrumb>
      {primary && (
        <BreadcrumbItem>
          <a href={'/lista/' + primary}>{primary.split('-').join(' ')}</a>
        </BreadcrumbItem>
      )}
      {secondary && (
        <BreadcrumbItem>
          <a href={'/lista/' + primary + '/' + secondary}>
            {secondary.split('-').join(' ')}
          </a>
        </BreadcrumbItem>
      )}
      {tertiary && (
        <BreadcrumbItem active>
          <a
            href={
              '/lista/' +
              primary +
              '/' +
              secondary +
              '/' +
              tertiary.split('-').join(' ')
            }
          >
            {tertiary}
          </a>
        </BreadcrumbItem>
      )}
      {selectedFilters &&
        selectedFilters.map((item, key) => (
          <BreadcrumbItem key={`BreadcrumbItem_${key}`} active>
            {item.join(' / ')}
          </BreadcrumbItem>
        ))}
    </Breadcrumb>
  );
};

const PaginationBtn = ({
  pagination = 1,
  updatePagination,
  showNext = true,
  pageCount,
}) => {
  pagination = parseInt(pagination);
  const pageSpan = 8;
  const start = Math.max(1, pagination - Math.floor(pageSpan / 2));
  const end = Math.min(start + pageSpan - 1, pageCount);

  return (
    <nav aria-label="Page navigation  " className="mt-5">
      <ul className="pagination justify-content-center">
        <li className={`page-item ${pagination === 1 ? 'd-none' : ''}`}>
          <button
            className="page-link"
            onClick={updatePagination}
            data-direction="back"
          >
            <i className="fas fa-chevron-left"></i> Atrás
          </button>
        </li>
        {Array.from({ length: pageSpan }, (_, index) => {
          const page_index = start + index;

          if (page_index > end) return null;
          return (
            <li
              key={`page_pagination_${index}`}
              className={`page-item ${
                pagination === page_index ? 'active' : ''
              }`}
            >
              <button
                className="page-link"
                onClick={updatePagination}
                data-direction={page_index}
              >
                {page_index}
              </button>
            </li>
          );
        })}
        <li className={`page-item ${showNext ? '' : 'disabled'}`}>
          <button
            className="page-link"
            onClick={updatePagination}
            data-direction="front"
          >
            Siguiente <i className="fas fa-chevron-right"></i>
          </button>
        </li>
      </ul>
    </nav>
  );
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function replaceWords(word) {
  // word = word.split(" ").join("-");
  // word = encodeURIComponent(word);
  return word.replace('Bano', 'Baño');
}

export default ProductList;
